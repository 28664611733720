/*! Create custom classes here which is frequently used */


.custom-btn {
    border-radius: 30px;
    border: none;
    width: 100%;
}

.brown-btn {
    background-color: #966837;
    border-radius: 50px;
    border: none;
    height: 40px;
    color: white;
    width: 100%;
}

.brown-btn:hover {
    background-color: #b07f4a;
}

.transparent-btn {
    color: white;
    background-color: transparent;
    border-radius: 50px;
    border: 1px solid #777777;
    height: 40px;
    width: 100%;
}

.custom_toast {
    height: '100%';
    width: '100%'
}

.link_toast {
    text-decoration: none;
    color: black;
}


/* CSS for product card overflow starts here*/

/* .card-overflow p:last-child {
    min-height: 200px;
} */
/* 
.btnDiv {
    bottom: 0;
    position: relative; 
     background-color: brown;
    align-self: flex-end;
} */

/* CSS for product card ends here*/


/* CSS for toast button in contact form starts here */

.toast-button {
    background-color: #966837 !important;
    border-radius: 50px !important;
    border: none !important;
    height: 40px !important;
    width: 30% !important;
    color: white !important;
    margin-right: 10px !important;
}

/* CSS for toast button in contact form ends here */


/* Request for quote button product name css */

.prdct-name {
    color: #966837;
    margin-left: 5px;

}

.article-rfq {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /* background-color: red; */
}

.remove-focus:active,
.remove-focus:focus {
    outline: none !important;
    /* box-shadow: red !important; */
    height: 40px !important;

    outline: 0;
    box-shadow: 0 0 0 0.125rem #d2d2d2 !important;

}

.remove-focus::placeholder {

    color: #d2d2d2 !important;
    opacity: 1;

}



.search-btn {
    background-color: #966837 !important;
    margin-left: 16px;
    border: none !important;

}

.search-btn:hover {
    background-color: #684723 !important;


}

/* custom select */

/* .custom-select {
    background: rgba(255, 255, 255, 0.1) !important;
    border: none !important;
    box-shadow: none !important;
    color: white !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: 38px !important;
} */

.form-select {
    color: white !important;
    border: none !important;
    background: rgba(255, 255, 255, 0.1) !important;
    box-shadow: none !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    height: 38px !important;

}

.options {
    color: black;

}

.otp-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.otp-btn button {
    width: 9rem;
}

.resend-otp {
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
}

.resend-otp a {
    color: #966837;
    font-size: 0.8rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
}

.resend-otp a:hover {
    color: #ffbc74;
}

.resend-otp-holder {
    /* background-color: #5c0000; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.resend-otp-holder>div>span {
    font-size: 14px;
}


/* Resend OTP in 00:12 */
.resend-otp-holder>div>span {
    color: #fff;

}

/* 00:12  */
.resend-otp-holder>div>span>span {
    color: #966837;
    font-size: 14px;
}

.resend-otp-holder>div>button>span {
    color: #966837;
    font-size: 16px;
}


.resend-otp-holder p {
    color: #fff;
    font-size: 0.8rem;
    padding-top: 1rem;
    padding-right: 10px;


}

.reset-btn-style {
    all: unset;
    color: #875d31;
}

.reset-btn-style>button :hover {
    color: #f6ab5a;
}


.icon {
    height: 14px;
    margin-left: 2px;
    margin-bottom: 5px;
}